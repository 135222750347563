import { css } from "@emotion/react";

/**
 * In order to avoid the download weight of fontawesome all.min.css and the 3 (r!) different font files we use, which weight almost 1MB,
 * instead we reference icons using individual SVG references.
 */

const Icon = css`
  display: inline-block;
  line-height: 1;
  height: 1em;
  background-color: currentColor;

  &:before {
    content: "";
  }
`;

export const DoubleSize = css`
  font-size: 2em;
`;

export const ScrewdriverWrench = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/screwdriver-wrench.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/screwdriver-wrench.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -5%;
  `,
];

export const Industry = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/industry.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/industry.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -5%;
  `,
];

export const ArrowsRotate = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrows-rotate.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrows-rotate.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -5%;
  `,
];

export const AngleDownSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-down.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-down.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -5%;
  `,
];

export const AngleLeftRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/angle-left.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/angle-left.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const AngleLeftSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-left.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-left.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const AngleRightRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/angle-right.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/angle-right.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const AngleRightSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-right.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-right.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const AngleUpSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/angle-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -5%;
  `,
];

export const ArrowLeftRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-left.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-left.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowLeftSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-left.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-left.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowRightRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-right.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-right.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowRightSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-right.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-right.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowRightToBracketRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-right-to-bracket.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-right-to-bracket.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowRightToBracketSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-right-to-bracket.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-right-to-bracket.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowUpRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowUpSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -12%;
  `,
];

export const BarsRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/bars.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/bars.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const BarsSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/bars.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/bars.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const CalendarDayRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/calendar-day.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/calendar-day.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const CalendarDaySolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/calendar-day.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/calendar-day.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const CaretDownRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/caret-down.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/caret-down.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const CaretDownSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/caret-down.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/caret-down.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const CaretUpRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/caret-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/caret-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const CaretUpSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/caret-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/caret-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 320 / 512;
    vertical-align: -12%;
  `,
];

export const ChartLineRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/chart-line.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/chart-line.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ChartLineSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/chart-line.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/chart-line.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const CheckRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/check.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/check.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -15%;
  `,
];

export const CheckSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/check.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/check.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -15%;
  `,
];

export const ClockRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/clock.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/clock.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ClockSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/clock.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/clock.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const CloudArrowDownRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/cloud-arrow-down.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/cloud-arrow-down.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -12%;
  `,
];

export const CloudArrowDownSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/cloud-arrow-down.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/cloud-arrow-down.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -12%;
  `,
];

export const CloudArrowUpRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/cloud-arrow-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/cloud-arrow-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -12%;
  `,
];

export const CloudArrowUpSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/cloud-arrow-up.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/cloud-arrow-up.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -12%;
  `,
];

export const CreditCardRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/credit-card.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/credit-card.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -12%;
  `,
];

export const CreditCardSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/credit-card.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/credit-card.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -12%;
  `,
];

export const UpRightAndDownLeftFromCenterRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/up-right-and-down-left-from-center.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/up-right-and-down-left-from-center.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const UpRightAndDownLeftFromCenterSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/up-right-and-down-left-from-center.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/up-right-and-down-left-from-center.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowUpRightFromSquareRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-up-right-from-square.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/arrow-up-right-from-square.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ArrowUpRightFromSquareSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-up-right-from-square.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/arrow-up-right-from-square.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const FileLinesRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/file-lines.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/file-lines.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -12%;
  `,
];

export const FileLinesSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/file-lines.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/file-lines.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -12%;
  `,
];

export const FileCertificateRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/file-certificate.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/file-certificate.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const FileCertificateSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/file-certificate.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/file-certificate.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const HeadphonesRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/headphones.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/headphones.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -15%;
  `,
];

export const HeadphonesSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/headphones.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/headphones.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -15%;
  `,
];

export const HouseRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/house.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/house.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -12%;
  `,
];

export const HouseSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/house.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/house.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -12%;
  `,
];

export const IndustryWindowsRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/industry-windows.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/industry-windows.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -15%;
  `,
];

export const IndustryWindowsSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/industry-windows.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/industry-windows.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -15%;
  `,
];

export const LeafRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/leaf.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/leaf.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -15%;
  `,
];

export const LeafSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/leaf.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/leaf.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -15%;
  `,
];

export const LinkRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/link.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/link.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -15%;
  `,
];

export const LinkSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/link.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/link.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -15%;
  `,
];

export const LinkLight = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/light/link.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/light/link.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 640 / 512;
    vertical-align: -15%;
  `,
];

export const LockRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/lock.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/lock.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const LockSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/lock.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/lock.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const LockKeyholeRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/lock-keyhole.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/lock-keyhole.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const LockKeyholeSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/lock-keyhole.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/lock-keyhole.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const MinusRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/minus.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/minus.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const MinusSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/minus.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/minus.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const MobileScreenButtonRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/mobile-screen-button.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/mobile-screen-button.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -12%;
  `,
];

export const MobileScreenButtonSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/mobile-screen-button.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/mobile-screen-button.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -12%;
  `,
];

export const MoneyBillWaveRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/money-bill-wave.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/money-bill-wave.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -15%;
  `,
];

export const MoneyBillWaveSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/money-bill-wave.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/money-bill-wave.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -15%;
  `,
];

export const PiggyBankRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/piggy-bank.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/piggy-bank.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -15%;
  `,
];

export const PiggyBankSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/piggy-bank.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/piggy-bank.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -15%;
  `,
];

export const CirclePlayRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/circle-play.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/circle-play.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const CirclePlaySolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/circle-play.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/circle-play.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const MagnifyingGlassRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/magnifying-glass.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/magnifying-glass.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const MagnifyingGlassSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/magnifying-glass.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/magnifying-glass.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const PlusRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/plus.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/plus.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const PlusSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/plus.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/plus.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const ShieldCheckRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/shield-check.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/shield-check.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const ShieldCheckSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/shield-check.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/shield-check.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const XMarkRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/xmark.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/xmark.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -15%;
  `,
];

export const XMarkSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/xmark.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/xmark.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 384 / 512;
    vertical-align: -15%;
  `,
];

export const RectangleXMarkRegular = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/rectangle-xmark.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/regular/rectangle-xmark.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const RectangleXMarkSolid = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/rectangle-xmark.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/solid/rectangle-xmark.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const Facebook = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/facebook.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/facebook.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const Twitter = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/x-twitter.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/x-twitter.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 512 / 512;
    vertical-align: -12%;
  `,
];

export const Instagram = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/instagram.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/instagram.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

export const Youtube = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/youtube.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/youtube.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 576 / 512;
    vertical-align: -12%;
  `,
];

export const Linkedin_In = [
  Icon,
  css`
    mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/linkedin-in.svg)
      no-repeat center / contain content-box;
    -webkit-mask: url(https://assets.xometry.com/fontawesome-pro/v6/svgs/brands/linkedin-in.svg)
      no-repeat center / contain content-box;
    aspect-ratio: 448 / 512;
    vertical-align: -12%;
  `,
];

// A replacement for .fa-ul
export const BulletedList = css`
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
  & > li {
    position: relative;
  }
`;

// A replacement for .fa-li
export const ListBullet = css`
  left: -2em;
  position: absolute;
  top: calc(1em * 0.15);
  text-align: center;
  width: 2em;
  line-height: inherit;
`;
